import './index.css'
import Head from '@/pages/Head'
import Newest from '@/pages/newest'
import Movie from '@/pages/vod-movie'
import Meiju from '@/pages/vod-meiju'
import Footer from '@/pages/footer'
import Comic from '@/pages/vod-comic'
import { Helmet } from 'react-helmet'
import Zongyi from '../vod-zongyi'
const Home = () => {
  return (
    <>
      <Helmet>
        <title>在线观看最新美剧|最新韩剧|天天美剧|人人美剧 - 找片儿</title>
      </Helmet>

      <Head />
      <Newest />
      <Movie />
      <Meiju />
      <Comic />
      <Zongyi />
      <Footer />
    </>
  )
}
export default Home;