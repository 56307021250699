import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import LiveTvIcon from '@mui/icons-material/LiveTv';

import SearchIcon from '@mui/icons-material/Search';
import MoreIcon from '@mui/icons-material/MoreVert';

import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import Footer from '../footer';

//使用styled构建自定义的search组件
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

//使用styled构建搜索icon组件
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

//使用styled构建搜索输入框组件
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
}));




const PrimarySearchAppBar = () => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
      <a className="text-gray-500  transition hover:text-gray-500/75" href="/vod/type/16-1.html">美剧</a>
      </MenuItem>
      <MenuItem>
      <a className="text-gray-500  transition hover:text-gray-500/75" href="/vod/type/1-1.html">电影</a>
      </MenuItem>
      <MenuItem>
      <a className="text-gray-500  transition hover:text-gray-500/75" href="/vod/type/2-1.html">连续剧</a>
      </MenuItem>
      <MenuItem>
      <a className="text-gray-500  transition hover:text-gray-500/75" href="/vod/type/20-1.html">纪录片</a>
      </MenuItem>
      <MenuItem>
      <a className="text-gray-500  transition hover:text-gray-500/75" href="/vod/type/15-1.html">韩剧</a>
      </MenuItem>
      <MenuItem>
      <a className="text-gray-500  transition hover:text-gray-500/75" href="/vod/type/22-1.html">日剧</a>
      </MenuItem>
      <MenuItem>
      <a className="text-gray-500  transition hover:text-gray-500/75" href="/vod/type/3-1.html">综艺</a>
      </MenuItem>
      <MenuItem>
      <a className="text-gray-500  transition hover:text-gray-500/75" href="/vod/type/4-1.html">动漫</a>
      </MenuItem>
    </Menu>
  );

  //创建自定义主题
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1976d2',
      },
    },
  });

  //--------------------------------------------------------------------------

  const navigate = useNavigate()
  const [keyWordsValue, setKeyWordsValue] = React.useState('')
 
  const handleIndexClick = ()=>{
    navigate("/")
  }

  //每次文本框输入都会触发
  const onEnterKey = (e)=>{
    //按回车搜索
    if(e.keyCode === 13 && keyWordsValue.trim()!=='' && keyWordsValue!=null){
        navigate("/vod/search/" + keyWordsValue.trim())
    }
  }

  return (
    <ThemeProvider theme={darkTheme}>

      <Box sx={{flexGrow: 1}}>

          <AppBar position="static" color="primary">
            <Toolbar className='items-center justify-between'>

              <IconButton size="medium" edge="start" color="inherit" aria-label="open drawer"sx={{ mr: 2 }} onClick={handleIndexClick}>
                <LiveTvIcon className='text-red-500' fontSize='large'/>
              </IconButton>
              
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>

                <StyledInputBase 
                  onChange={(e) => setKeyWordsValue(e.target.value)} 
                  onKeyUp={onEnterKey}
                  value={keyWordsValue}
                  placeholder="张国荣 霸王别姬 诺兰 …"
                  inputProps={{ 'aria-label': 'search' }}
                />
              </Search>

              <div className="hidden md:block">
                    <nav aria-label="Site Nav">
                    <ul className="flex items-center gap-6 text-base">
                        <li><a className="text-gray-400  transition hover:text-gray-500/75" href="/vod/type/16-1.html">美剧</a></li>
                        <li><a className="text-gray-400  transition hover:text-gray-500/75" href="/vod/type/1-1.html">电影</a></li>
                        <li><a className="text-gray-400  transition hover:text-gray-500/75" href="/vod/type/2-1.html">连续剧</a></li>
                        <li><a className="text-gray-400  transition hover:text-gray-500/75" href="/vod/type/20-1.html">纪录片</a></li>
                        <li><a className="text-gray-400  transition hover:text-gray-500/75" href="/vod/type/15-1.html">韩剧</a></li>
                        <li><a className="text-gray-400  transition hover:text-gray-500/75" href="/vod/type/22-1.html">日剧</a></li>
                        <li><a className="text-gray-400  transition hover:text-gray-500/75" href="/vod/type/3-1.html">综艺</a></li>
                        <li><a className="text-gray-400  transition hover:text-gray-500/75" href="/vod/type/4-1.html">动漫</a></li>
                    </ul>
                    </nav>
              </div>

              <Box className="md:hidden">
                <IconButton size="medium" aria-label="show more" aria-controls={mobileMenuId}
                  aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
                  <MoreIcon />
                </IconButton>
              </Box>


              {/* 三个独立的按钮菜单 */}
              {/* <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                  <Badge badgeContent={4} color="error">
                    <MailIcon />
                  </Badge>
                </IconButton>
                <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
                  <Badge badgeContent={500} color="error">
                    <NotificationsIcon />
                  </Badge>
                </IconButton>
                <IconButton size="large" edge="end" aria-label="account of current user" aria-controls={menuId}
                  aria-haspopup="true" onClick={handleProfileMenuOpen} color="inherit">
                  <AccountCircle />
                </IconButton>
              </Box> */}

            </Toolbar>
          </AppBar>

        {renderMobileMenu}

      </Box>
      <Outlet />

      <Footer />
    </ThemeProvider>
  );
}
export default observer(PrimarySearchAppBar);