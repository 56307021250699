import axios from 'axios'

const http = axios.create({
    baseURL: "https://api.z-booster.com",  //线上
    //  baseURL: "http://localhost:8089", //开发
    timeout: 30000
})

http.interceptors.request.use((config) => { 
    
    // todo token放在request header 

    return config
}, (error) => {
    return Promise.reject(error)
})

http.interceptors.response.use((response) => {
    return response.data
}, (error) => {
    return Promise.reject(error)
})
export {http}