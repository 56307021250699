import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import VodCardList from '@/pages/vod-card';
import { Container, Grid, Pagination } from '@mui/material';
import useDataApi from '@/hooks/useDataApi';
import { Helmet } from 'react-helmet';

const SearchList = () => {
    const { keyword } = useParams()
    const [currentPage, setCurrentPage] = useState(1)
    const [{ dataResult, isLoading, isError, pageNum }, search] = useDataApi({
        url: '/zhaopr/searchVodByKeyword',
        keyWords: keyword,
        pageSize: 12,
        currentPage: 1
    })

    const handleChange = (event, value) => {
        setCurrentPage(value)
        search({
            url: '/zhaopr/searchVodByKeyword',
            keyWords: keyword,
            pageSize: 12,
            currentPage: value
        })
    }

    useEffect(() => {
        search({
            url: '/zhaopr/searchVodByKeyword',
            keyWords: keyword,
            pageSize: 12,
            currentPage: currentPage
        })
    }, [keyword, currentPage, search])

    return (
        <>
            {isError && <div>ERROR!!!</div>}
            {isLoading ? <div>loading.....</div> :

                <div className="vod-category">

                    <Helmet>
                        <title>在线观看最新美剧|最新韩剧|天天美剧|人人美剧 - 找片儿</title>
                    </Helmet>

                    <VodCardList vodResultList={dataResult} title={keyword} />

                    {(dataResult == null || dataResult.length <= 0 || pageNum <= 0) ? <div></div> :
                        <Container maxWidth='lg' sx={{ marginTop: '25px' }}>
                            <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
                                <Pagination count={pageNum} page={currentPage} variant="outlined" shape="rounded" onChange={handleChange} />
                            </Grid>
                        </Container>
                    }
                </div>
            }
        </>
    )
}
export default SearchList;