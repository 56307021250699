import { useEffect, useState } from 'react'
import { http } from '@/utils'

const useVodDetail = (_vodId) => {
    const [vodDetailResult, setVodDetailResult] = useState(null)

    const [vodId, setVodId] = useState(_vodId)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    useEffect(()=>{
        const search = async (vodId)=>{
            setIsError(false)
            setIsLoading(true)
            try{
                const result = await http.get('/zhaopr/detail?vodId=' + vodId)
                setVodDetailResult(result.data)
            }catch(error){
                setIsError(true)
            }
            setIsLoading(false)
        }
        search(vodId)
        return ()=>{
            setIsError(false)
            setIsLoading(false)
            setVodId(null)
            setVodDetailResult(null)
        }
    }, [vodId])

    return [{vodDetailResult, isLoading, isError}, setVodId]
}
export default useVodDetail