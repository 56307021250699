import VodCardList from '@/pages/vod-card';
import useDataApi from '@/hooks/useDataApi';

const Newest = ()=>{
    const [{dataResult, isLoading, isError}] = useDataApi({
        url: '/zhaopr/newestVod',
        pageSize: 12,
        currentPage: 1
    })

    return(
        <>
            {isError && <div>ERROR!!!</div>}
            {isLoading ? <div>loading.....</div> :
                <div className="vod-newest">
                    <VodCardList vodResultList={dataResult} title={'最新'} />
                </div>
            }
        </>
    )
}
export default Newest;