import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, CardActionArea, Container, Grid } from '@mui/material';
import {useNavigate} from 'react-router-dom'

function VodCardList(props) {
  const navigate = useNavigate()

  const handleCardClick = (vodId) => {
    navigate('/vod/detail/'+ vodId +'.html');
    window.location.reload()
  }

  const VodCard = (props)=>{
    const detailUrl = '/vod/detail/' + props.vodId + '.html'
    return(
      <>
  
        <Card sx={{ maxWidth: 345}} onClick={()=>handleCardClick(props.vodId)}>
          <CardActionArea sx={{ bgcolor: '#121212'}}>
            <CardMedia  
              component="img"
              image={props.url}
              alt="{props.vodName}" sx={{height: 250, objectFit: 'cover', maxWidth: '100%', maxHeight: '100%' }}
            />
            <CardContent>
              <Typography gutterBottom variant="h7" component="div" sx={{textAlign: 'center'}}>
                <p className='text-rose-200 truncate ...'>
                  <a href={detailUrl}>{props.vodName}</a>
                </p>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      
      </>
    )
  }

  return (
    <>
      <Container maxWidth='lg' sx={{marginTop: '22px'}}>
        
          <Typography component="div" className='text-gray-300' sx={{ textAlign: 'left', fontSize: 'h7.fontSize'}}>{props.title}</Typography>

          <Box mt={3}>
            <Grid container spacing={2} columns={{ xs: 4, sm: 6, md: 12 }} >
                {props.vodResultList.map((vodSummary, index) =>
                  <Grid item xs={2}>
                    <VodCard key={index} vodId={vodSummary.vodId} url={vodSummary.vodPic} vodName={vodSummary.vodName} vodActor={vodSummary.vodActor} />
                  </Grid>
                )}
            </Grid>
          </Box>

      </Container>
    </>
  );
}
export default VodCardList;