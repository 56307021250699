const Footer = () => {
    return (
        <>
            <div class="items-center my-12 text-gray-500 text-sm">
                本站所有视频和图片均来自互联网收集而来，版权归原创者所有，本网站只提供web页面服务，并不提供资源存储，<br />
                也不参与录制、上传，若本站收录的节目无意侵犯了贵司版权，请联系邮箱：zhaoprr#gmail.com(#改为@)，本站将于24小时内删除
            </div>
        </>
    )
}
export default Footer;