import VodCardList from "@/pages/vod-card"
import useDataApi from "@/hooks/useDataApi"

const Zongyi = ()=>{
    const [{dataResult, isLoading, isError}] = useDataApi({
        url: '/zhaopr/searchVod',
        typeId: 3,
        pageSize: 12,
        currentPage: 1
    })

    return(
        <>
            {isError && <div>ERROR!!!</div>}
            {isLoading ? <div>loading.....</div> :
                <div className="vod-zongyi">
                    <VodCardList vodResultList={dataResult} title={'综艺'} />
                </div>
            }
        </>
    )
}
export default Zongyi;