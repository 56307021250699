import React from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Layouts from '@/pages/Layout'
import Home from '@/pages/Home'
import Detail from '@/pages/Detail'
import Play from '@/pages/Play'
import TypeList from '@/pages/type-list'
import SearchList from '@/pages/search-list'

const Router = () => {
    return(
        <BrowserRouter>
            <Routes>
                <Route index element={<Home />} />

                <Route path='/' element={<Layouts />}>
                    <Route path='vod/play/:vodId-1-:number.html' element={<Play />} />
                    <Route path='vod/type/:vodType-:pageNo.html' element={<TypeList />} />
                    <Route path='vod/detail/:vodId.html' element={<Detail />} />
                    <Route path='vod/search/:keyword' element={<SearchList />} />
                </Route>
               
                <Route path='/login' element={<div>login...</div>} />
                <Route path='*' element={<div>404</div>}/>
            </Routes>
        </BrowserRouter>
    );
}
export default Router;