import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import VodCardList from '@/pages/vod-card';
import useDataApi from '@/hooks/useDataApi';
import { Button, Container, Grid, Pagination, PaginationItem } from '@mui/material';
import { Helmet } from 'react-helmet';

const TypeList = () => {
    const { vodType, pageNo } = useParams()
    const _vodType = Number(vodType)
    const _pageNo = Number(pageNo)
    const navigate = useNavigate()
    const [currentVodType, setCurrentVodType] = useState(_vodType)
    const [currentSubVodType, setCurrentSubVodType] = useState([])

    const [{ dataResult, isLoading, isError, pageNum }, search] = useDataApi({
        url: '/zhaopr/searchVod',
        typeId: _vodType,
        pageSize: 12,
        currentPage: _pageNo
    })

    const handleVodTypeSearch = (vodTypeId) => {
        navigate('/vod/type/' + vodTypeId + '-1.html')
        setCurrentVodType(vodTypeId)
        search({
            url: '/zhaopr/searchVod',
            typeId: vodTypeId,
            pageSize: 12,
            currentPage: _pageNo
        })
    }

    useEffect(() => {
        const vodTypeData = [
            {
                "name": '电影',
                "typeId": 1,
                "pid": 0,
                "sub": [
                    {
                        "name": '动作',
                        "typeId": 6,
                        "pid": 1
                    },
                    {
                        "name": '喜剧',
                        "typeId": 7,
                        "pid": 1
                    },
                    {
                        "name": '爱情',
                        "typeId": 8,
                        "pid": 1
                    },
                    {
                        "name": '科幻',
                        "typeId": 9,
                        "pid": 1
                    },
                    {
                        "name": '恐怖',
                        "typeId": 10,
                        "pid": 1
                    },
                    {
                        "name": '剧情',
                        "typeId": 11,
                        "pid": 1
                    },
                    {
                        "name": '战争',
                        "typeId": 12,
                        "pid": 1
                    },
                    {
                        "name": '记录片',
                        "typeId": 20,
                        "pid": 1
                    },
                    {
                        "name": '伦理',
                        "typeId": 34,
                        "pid": 1
                    }
                ]
            },
            {
                "name": '连续剧',
                "typeId": 2,
                "pid": 0,
                "sub": [
                    {
                        "name": '国产剧',
                        "typeId": 13,
                        "pid": 2
                    },
                    {
                        "name": '香港剧',
                        "typeId": 14,
                        "pid": 2
                    },
                    {
                        "name": '韩国剧',
                        "typeId": 15,
                        "pid": 2
                    },
                    {
                        "name": '欧美剧',
                        "typeId": 16,
                        "pid": 2
                    },
                    {
                        "name": '台湾剧',
                        "typeId": 21,
                        "pid": 2
                    },
                    {
                        "name": '日本剧',
                        "typeId": 22,
                        "pid": 2
                    },
                    {
                        "name": '海外剧',
                        "typeId": 23,
                        "pid": 2
                    },
                    {
                        "name": '泰国剧',
                        "typeId": 24,
                        "pid": 2
                    }
                ]
            },
            {
                "name": '综艺',
                "typeId": 3,
                "pid": 0,
                "sub": [
                    {
                        "name": '大陆综艺',
                        "typeId": 25,
                        "pid": 3
                    },
                    {
                        "name": '港台综艺',
                        "typeId": 26,
                        "pid": 3
                    },
                    {
                        "name": '日韩综艺',
                        "typeId": 27,
                        "pid": 3
                    },
                    {
                        "name": '欧美综艺',
                        "typeId": 28,
                        "pid": 3
                    },
                ]
            },
            {
                "name": '动漫',
                "typeId": 4,
                "pid": 0,
                "sub": [
                    {
                        "name": '国产动漫',
                        "typeId": 29,
                        "pid": 4
                    },
                    {
                        "name": '日韩动漫',
                        "typeId": 30,
                        "pid": 4
                    },
                    {
                        "name": '欧美动漫',
                        "typeId": 31,
                        "pid": 4
                    },
                    {
                        "name": '港台动漫',
                        "typeId": 32,
                        "pid": 4
                    },
                    {
                        "name": '海外动漫',
                        "typeId": 33,
                        "pid": 4
                    }
                ]
            },
            {
                "name": '电影解说',
                "typeId": 35,
                "pid": 0
            }
        ]

        vodTypeData.map((typeItem, index) => {
            if (currentVodType === typeItem.typeId) {
                if (typeItem.sub && typeItem.sub.length > 0) {
                    setCurrentSubVodType(typeItem.sub)
                }
            } else {
                if (typeItem.sub && typeItem.sub.length > 0) {
                    typeItem.sub.map((subTypeItem, index) => {
                        if (currentVodType === subTypeItem.typeId) {
                            setCurrentSubVodType(typeItem.sub)
                        }
                        return {}
                    })
                }
            }
            return {}
        }
        )
    }, [currentVodType])

    return (
        <>
            {isError && <div>ERROR!!!</div>}
            {isLoading ? <div>loading.....</div> :

                <div className="vod-category">

                    <Helmet>
                        <title>在线观看最新美剧|最新韩剧|天天美剧|人人美剧 - 找片儿</title>
                    </Helmet>

                    <Container maxWidth='lg' sx={{ marginTop: '25px' }}>
                        <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
                            <div className='md:flex md:flex-row text-left'>
                                {
                                    currentSubVodType.map((subTypeItem, index) =>
                                        <Button onClick={() => handleVodTypeSearch(subTypeItem.typeId)} sx={{ color: 'gainsboro', bgcolor: _vodType === subTypeItem.typeId ? '#c5464a' : '' }} variant='text' color="info">
                                            {subTypeItem.name}
                                        </Button>
                                    )
                                }
                            </div>
                        </Grid>
                    </Container>

                    <VodCardList vodResultList={dataResult} title={''} />

                    {(dataResult == null || dataResult.length <= 0 || pageNum <= 0) ? <div></div> :
                        <Container maxWidth='lg' sx={{ marginTop: '25px' }}>
                            <Grid container columns={{ xs: 4, sm: 6, md: 12 }}>
                                <Pagination count={pageNum} page={_pageNo}
                                    renderItem={(item) => {
                                        return <PaginationItem {...item} component="a" href={`/vod/type/${_vodType}-${item.page}.html`} />
                                    }}
                                />
                            </Grid>
                        </Container>
                    }
                </div>
            }
        </>
    )
}
export default TypeList;