import { http } from "@/utils"
import { useEffect, useState } from "react"

const useDataApi = (initialParams) => {
    const [pageNum, setPageNum] = useState(0)
    const [dataResult, setDataResult] = useState([])
    const [queryParams, setQueryParams] = useState(initialParams)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    
    useEffect(()=>{
        const fetchData = async (queryParams)=>{
            setIsLoading(true)
            setIsError(false)
            try {
                // console.log(queryParams)
                await http.post(queryParams.url, queryParams).then(res => {
                    // console.log('.....', res)
                    setDataResult(res.data)
                    setPageNum(res.pageNum)
                })    
            } catch (error) {
                setIsError(true)
            }
            setIsLoading(false)  
        }

        if(queryParams!=null && JSON.stringify(queryParams)!=='{}'){
            fetchData(queryParams)
        }
    }, [queryParams])
    
    return [{dataResult, isLoading, isError, pageNum}, setQueryParams]
}
export default useDataApi