import Player from '@pansy/react-aliplayer';
import { useEffect, useState, useRef } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import useVodDetail from '@/hooks/useVodDetail';
import { Grid } from '@mui/material';
import { Helmet } from 'react-helmet';

const Play = () => {
    const playerRef = useRef(null);

    const navigate = useNavigate()
    const {vodId, number} = useParams() //number是字符串要转为数字
    const [{vodDetailResult, isLoading, isError}] = useVodDetail(vodId)
    const [currentPlayUrl, setCurrentPlayUrl] = useState('')

    useEffect(()=>{
        if(vodDetailResult!=null){
            Object.keys(vodDetailResult.m3u8Urls).map((key, index)=>{
                let _number = parseInt(number, 10)
                if (_number === (index+1)){
                    setCurrentPlayUrl(vodDetailResult.m3u8Urls[key])
                }
                return {}
            })
        }

        // const _player = playerRef.current;
        // if(_player!=null){
        //     console.log(_player)
            // _player && _player.setVolume(0.5)
        // }
        // _player && _player.loadByUrl();
    },[number, vodDetailResult])

    const handlePlayVod = (index) => {
        navigate('/vod/play/' + vodId + '-1-' + (index+1) + '.html')
    }

    return(
        <>

            {isError && <div>ERROR!!!</div>}            
            {(isLoading || vodDetailResult===null) ? <div>loading...</div> : 
            <div>

            <Helmet>
                <title>{vodDetailResult.vodName} - 找片儿</title>
            </Helmet>

                <Container maxWidth="lg" sx={{marginTop: '40px'}}>
                <Box>

                    <Grid container h-auto spacing={1} columns={{xs: 2, sm: 2, md: 8}}>
                        <Grid xs={2} sm={2} md={6}>
                            <div id='sdssss' className="min-w-max min-h-max">

                                <Player
                                    source={currentPlayUrl}
                                    ref={playerRef}
                                    options={{
                                        autoplay: true,
                                        height: '500px',
                                        preload: true,
                                        isLive: false
                                    }}
                                />

                            </div>
                        </Grid>

                        <Grid xs={2} sm={2} md={2}>
                            <div className="h-auto borde-solid  ">
                                <div className="py-4 text-center font-normal text-gray-200">
                                    {vodDetailResult.vodName}
                                </div>
                                <div className="leading-10 text-left pl-6">
                                    {
                                        Object.keys(vodDetailResult.m3u8Urls).map((key, index)=>{
                                            return <Button sx={{color: 'gainsboro',  bgcolor: (parseInt(number, 10)===index+1) ? '#c5464a' : '' }}  onClick={()=>{handlePlayVod(index)}} color="info">{key}</Button>
                                        })
                                    }
                                </div>
                            </div>
                        </Grid>

                    </Grid>
    
                </Box>
                </Container>
                
            </div>
            }

        </>
    )
}
export default Play;