import VodCardList from '@/pages/vod-card'
import useDataApi from '@/hooks/useDataApi'

const Meiju = ()=> {
    const [{dataResult, isLoading, isError}] = useDataApi({
        url: '/zhaopr/searchVod',
        typeId: 16,
        pageSize: 12,
        currentPage: 1
    })

    return (
            <>
                {isError && <div>ERROR!!!</div>}
                {isLoading ? <div>loading.....</div> :
                    <div className="vod-meiju">
                        <VodCardList vodResultList={dataResult} title={'美剧'} />
                    </div>
                }
            </>
    )
}
export default Meiju;