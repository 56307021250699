import { styled, alpha } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//使用styled构建自定义的search组件
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
    },
}));

//使用styled构建搜索icon组件
const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

//使用styled构建搜索输入框组件
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
        width: '30ch',
    },
    },
}));



const Search2 = ()=>{
    const navigate = useNavigate()
    const [keyWordsValue, setKeyWordsValue] = useState('')

    //按回车搜索
    const onEnterKey = (e)=>{
        if(e.keyCode === 13 && keyWordsValue!==''){
            navigate("/vod/search/" + keyWordsValue)
        }
    }

    //创建自定义主题
    const darkTheme = createTheme({
        palette: {
        mode: 'dark',
        primary: {
            main: '#1976d2',
        },
        },
    });

    return(
        <>
            <ThemeProvider theme={darkTheme}>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>

                    <StyledInputBase 
                        value={keyWordsValue}
                       onChange={(e) => setKeyWordsValue(e.target.value)} 
                        onKeyUp={onEnterKey}
                        placeholder="张国荣 霸王别姬 诺兰 …"
                        inputProps={{ 'aria-label': 'search' }}
                    />

                </Search>
            </ThemeProvider>
        </>
    )
}
export default Search2