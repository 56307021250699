import VodCardList from '@/pages/vod-card'
import useDataApi from '@/hooks/useDataApi'

const Comic = ()=> {
    const [{dataResult, isLoading, isError}] = useDataApi({
        url: '/zhaopr/searchVod',
        typeId: 4,
        pageSize: 12,
        currentPage: 1
    })

    return (
            <>
                {isError && <div>ERROR!!!</div>}
                {isLoading ? <div>loading.....</div> :
                    <div className="vod-comic">
                        <VodCardList vodResultList={dataResult} title={'动漫'} />
                    </div>
                }
            </>
    )
}
export default Comic;