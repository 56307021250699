import React from 'react'
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import Search2 from '../search2'
import IconButton from '@mui/material/IconButton';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import { useNavigate } from 'react-router-dom';

function Head() {
    const navigate = useNavigate()

    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <a className="text-gray-500  transition hover:text-gray-500/75" href="/vod/type/16-1.html">美剧</a>
            </MenuItem>
            <MenuItem>
                <a className="text-gray-500  transition hover:text-gray-500/75" href="/vod/type/1-1.html">电影</a>
            </MenuItem>
            <MenuItem>
                <a className="text-gray-500  transition hover:text-gray-500/75" href="/vod/type/2-1.html">连续剧</a>
            </MenuItem>
            <MenuItem>
                <a className="text-gray-500  transition hover:text-gray-500/75" href="/vod/type/20-1.html">纪录片</a>
            </MenuItem>
            <MenuItem>
                <a className="text-gray-500  transition hover:text-gray-500/75" href="/vod/type/15-1.html">韩剧</a>
            </MenuItem>
            <MenuItem>
                <a className="text-gray-500  transition hover:text-gray-500/75" href="/vod/type/22-1.html">日剧</a>
            </MenuItem>
            <MenuItem>
                <a className="text-gray-500  transition hover:text-gray-500/75" href="/vod/type/3-1.html">综艺</a>
            </MenuItem>
            <MenuItem>
                <a className="text-gray-500  transition hover:text-gray-500/75" href="/vod/type/4-1.html">动漫</a>
            </MenuItem>
        </Menu>
    );

    const MainMenu = () => {
        return (
            <>
                <div className="hidden md:block">
                    <nav aria-label="Site Nav">
                        <ul className="flex items-center gap-6 text-base">

                            <li><a className="text-gray-400  transition hover:text-gray-500/75" href="/vod/type/16-1.html">美剧</a></li>
                            <li><a className="text-gray-400  transition hover:text-gray-500/75" href="/vod/type/1-1.html">电影</a></li>
                            <li><a className="text-gray-400  transition hover:text-gray-500/75" href="/vod/type/2-1.html">连续剧</a></li>
                            <li><a className="text-gray-400  transition hover:text-gray-500/75" href="/vod/type/20-1.html">纪录片</a></li>
                            <li><a className="text-gray-400  transition hover:text-gray-500/75" href="/vod/type/15-1.html">韩剧</a></li>
                            <li><a className="text-gray-400  transition hover:text-gray-500/75" href="/vod/type/22-1.html">日剧</a></li>
                            <li><a className="text-gray-400  transition hover:text-gray-500/75" href="/vod/type/3-1.html">综艺</a></li>
                            <li><a className="text-gray-400  transition hover:text-gray-500/75" href="/vod/type/4-1.html">动漫</a></li>

                        </ul>
                    </nav>
                </div>
            </>
        )
    }

    const handleIndexClick = () => {
        navigate("/")
    }

    return (
        <>

            <header aria-label="Site Header" style={{ "backgroundColor": '#282c34' }}>
                <div className="mx-auto max-w-screen-xl px-4 pt-3 sm:px-6 lg:px-8">
                    <div className="flex h-16 items-center justify-between">
                        <div className="md:flex md:items-center md:gap-12">
                            <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" sx={{ mr: 2 }} onClick={handleIndexClick}>
                                <LiveTvIcon className='text-red-500' fontSize='large' />
                            </IconButton>
                        </div>
                        <Search2 />
                        <MainMenu />

                        <Box className="md:hidden">
                            <IconButton size="medium" aria-label="show more" aria-controls={mobileMenuId}
                                aria-haspopup="true" onClick={handleMobileMenuOpen} color="inherit">
                                <MoreIcon />
                            </IconButton>
                        </Box>
                    </div>
                </div>
                {renderMobileMenu}
            </header>

        </>
    )
}
export default Head