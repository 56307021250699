import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react';
import useVodDetail from '@/hooks/useVodDetail';
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet';
import useDataApi from '@/hooks/useDataApi';
import VodCardList from '../vod-card';

const Detail = () => {
    const navigate = useNavigate()
    const [vodDetail, setVodDetail] = useState(null)
    const { vodId } = useParams()
    const [{ vodDetailResult, isLoading, isError }] = useVodDetail(vodId)

    const [{ dataResult}, search] = useDataApi({})

    useEffect(() => {
        setVodDetail(vodDetailResult)
        if (vodDetailResult != null) {
            search({
                url: '/zhaopr/suggestion',
                typeId: vodDetailResult.typeId,
                vodName: vodDetailResult.vodName,
                vodClass: vodDetailResult.vodClass,
                director: vodDetailResult.vodDirector,
                pageSize: 12,
                currentPage: 1
            })
        }
    }, [vodDetailResult, search])

    const handlePlayClick = () => {
        navigate('/vod/play/' + vodId + '-1-1.html');
    }

    const handlePlayVod = (index) => {
        navigate('/vod/play/' + vodId + '-1-' + (index + 1) + '.html')
    }

    return (
        <>
            {isError && <div>ERROR!!!</div>}
            {(isLoading || vodDetail == null) ? <div>loading...</div> :
                <div>

                    <Helmet>
                        <title>{vodDetailResult.vodName} - 找片儿</title>
                    </Helmet>

                    <Container maxWidth="lg" sx={{ marginTop: '17px' }}>
                        <Box mt={5}>
                            <Grid container spacing={1} columns={{ xs: 2, sm: 2, md: 8 }}>
                                <Grid xs={2} sm={2} md={3}>
                                    <div className='overflow-hidden px-7'>
                                        <img src={vodDetail.vodPic} alt='img' className="max-h-96 max-w-full object-cover inline-grid rounded-lg shadow-2xl  " />
                                    </div>
                                    <p className='py-8'>
                                        <button className="btn w-44 font-normal text-lg" onClick={handlePlayClick} style={{ background: '#c5464a' }}>立即播放</button>
                                    </p>
                                </Grid>
                                <Grid xs={2} sm={2} md={5}>
                                    <div>
                                        <h1 className="text-2xl font-light">{vodDetail.vodName}</h1>
                                        <p dangerouslySetInnerHTML={{ __html: vodDetail.vodContent }} className="py-6 text-left text-gray-300 font-thin leading-relaxed">
                                        </p>
                                        <div className='font-thin text-left text-gray-300 space-y-2'>
                                            <p>类型：{vodDetail.vodClass}</p>
                                            <p>地区：{vodDetail.vodArea}</p>
                                            <p>年份：{vodDetail.vodYear}</p>
                                            <p>主演：{vodDetail.vodActor}</p>
                                            <p>导演：{vodDetail.vodDirector}</p>
                                            <p>更新：{vodDetail.vodTime}</p>
                                        </div>
                                    </div>
                                    <div className='pt-10 text-start text-gray-300 font-thin'>

                                        <div className="h-auto borde-solid  ">
                                            <div className="text-left">
                                                {
                                                    Object.keys(vodDetailResult.m3u8Urls).map((key, index) => {
                                                        return <Button onClick={() => { handlePlayVod(index) }} variant='text' sx={{ color: 'gainsboro' }}  >{key}</Button>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} columns={{ xs: 2, sm: 2, md: 8 }}>
                                <div className='pt-20 text-lg text-left md:px-7 text-gray-300'>
                                    猜你喜欢

                                    {isError && <div>ERROR!!!</div>}
                                    {isLoading && dataResult===[] ? <div>loading.....</div> :
                                        <div className="vod-suggestion">
                                            <VodCardList vodResultList={dataResult} title={''} />
                                        </div>
                                    }

                                </div>
                            </Grid>
                        </Box>
                    </Container>
                </div>
            }
        </>
    )
}
export default Detail;